import Link from "next/link";
import { useRouter } from "next/router";
import useSWR, { mutate } from "swr";
import { useTranslation } from "next-i18next";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { forwardRef } from "react";
import LoginSvg from "@/public/images/login";
import LogoColor from "@/public/images/logoColor";
import MenuIcon from "@/public/images/menu.js";
import storage from "util/storage";
import isLoggedIn from "util/isLoggedIn";
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'


export default function Navbar() {
  const { t } = useTranslation("common");
  const router = useRouter();
  const { data: currentUser } = useSWR("user", storage);
  const userLoggedIn = isLoggedIn(currentUser);

  const logoutUser = async () => {
    const res = await fetch("/api/logout");
    const data = await res.json();
    window.localStorage.setItem("user", JSON.stringify(data.token));
    mutate("user", data.token);
    router.push("/");
  };

  // return (
  //   <>
  //     <div className="grid grid-cols-2 gap-4">
  //       <div>01</div>
  //       <div>09</div>
  //     </div>
  //
  //     <div className="flex bg-white sticky top-0 shadow-nav justify-start px-4 md:px-8 py-2 z-10 items-center">
  //       <Link href="/">
  //         <a className="flex" aria-label="Home">
  //           <LogoColor />
  //         </a>
  //       </Link>
  //
  //       <Link href="/">
  //         <a className="">About us</a>
  //       </Link>
  //
  //       <Link href="/">
  //         <a className="">About us2</a>
  //       </Link>
  //     </div>
  //   </>
  // );

  return (
    <Disclosure as="nav" className="bg-white shadow-nav">
      {({ open }) => (
        <>
          <div className="mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <div className="h-auto w-auto lg:block">
                    <LogoColor />
                  </div>
                </div>
                <div className="hidden sm:ml-10 sm:flex sm:space-x-8">
                  {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                  <a href="#" className="inline-flex text-gray-900 items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700">
                    Property Tax Loans
                  </a>
                  <a href="#" className="inline-flex text-gray-900 items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700">
                    Our Loan Process
                  </a>
                  <a href="/faq" className="inline-flex text-gray-900 items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium hover:border-gray-300 hover:text-gray-700">
                    FAQ
                  </a>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pt-2 pb-4">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-indigo-500 bg-indigo-50 py-2 pl-3 pr-4 text-base font-medium text-indigo-700"
              >
                Dashboard
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Team
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Projects
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
              >
                Calendar
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

// <SmallScreenDropdown router={router} t={t} userLoggedIn={userLoggedIn} logoutUser={logoutUser} />
//
// <div className="hidden md:flex items-center">
//   {userLoggedIn ? (
//     <BigScreenDropDown logoutUser={logoutUser} router={router} t={t} />
//   ) : (
//     <>
//       <Link href={`${router.asPath}`} locale={router.locale === "en" ? "es" : "en"}>
//         <a>{t("change-locale")}</a>
//       </Link>
//       <Link href="/signin">
//         <a className="flex items-center ml-5 primary rounded-[14px] px-3 h-8 gap-2">
//           <LoginSvg />
//           <span>Sign in</span>
//         </a>
//       </Link>
//     </>
//   )}
// </div>

function LoginDropdown() {
  return (
    <Menu as="div" className="relative">
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <MyLink className="block px-4 py-2 hover:bg-gray-50 rounded-t-md" href="/user/signin">
              Private Signin
            </MyLink>
          </Menu.Item>
          <Menu.Item>
            <MyLink className="block px-4 py-2 hover:bg-gray-50 rounded-b-md" href="/business/signin">
              Business Signin
            </MyLink>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

function BigScreenDropDown({ router, t, logoutUser }) {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="secondary flex h-8 px-3 items-center">
        <LoginSvg />
        <div className="ml-2 leading-none">Account</div>
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <MyLink
              className="block px-4 py-2 hover:bg-gray-50 rounded-t-md"
              href={`${router.asPath}`}
              locale={router.locale === "en" ? "es" : "en"}
            >
              {t("change-locale")}
            </MyLink>
          </Menu.Item>
          <Menu.Item>
            <button className="block px-4 py-2 hover:bg-gray-50 w-full text-left rounded-b-md" onClick={logoutUser}>
              Logout
            </button>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const MyLink = forwardRef((props, ref) => {
  let { href, locale, children, ...rest } = props;
  return locale ? (
    <Link locale={locale} href={href}>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  ) : (
    <Link href={href}>
      <a ref={ref} {...rest}>
        {children}
      </a>
    </Link>
  );
});

function SmallScreenDropdown({ router, t, userLoggedIn, logoutUser }) {
  return (
    <Menu as="div" className="relative md:hidden">
      <Menu.Button aria-label="navbar dropdown toggler" className="flex h-8 px-3 items-center">
        <MenuIcon />
      </Menu.Button>
      <Transition
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item>
            <MyLink
              className="block px-4 py-2 hover:bg-gray-50 rounded-t-md"
              href={`${router.asPath}`}
              locale={router.locale === "en" ? "es" : "en"}
            >
              {t("change-locale")}
            </MyLink>
          </Menu.Item>
          {userLoggedIn ? (
            <Menu.Item>
              <button className="block px-4 py-2 hover:bg-gray-50 w-full text-left rounded-b-md" onClick={logoutUser}>
                Logout
              </button>
            </Menu.Item>
          ) : (
            <>
              <Menu.Item>
                <MyLink className="block px-4 py-2 hover:bg-gray-50 " href="/user/signin">
                  Signin
                </MyLink>
              </Menu.Item>
              <Menu.Item>
                <MyLink className="block px-4 py-2 hover:bg-gray-50 rounded-b-md" href="/user/signup">
                  Sign Up
                </MyLink>
              </Menu.Item>
            </>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
