export default function LogoColor() {
  return (
    <svg
      id="Logo_Color_Layer_1"
      data-name="Logo Color Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 132.16 60.06"
      width={96}
      height={44}
    >
      <defs>
        <clipPath id="lc-clip-path" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M32.47,66.65a5.93,5.93,0,0,0-2.58,2.45v-.51a2.36,2.36,0,0,0-.67-1.7,2.22,2.22,0,0,0-1.68-.69,2.26,2.26,0,0,0-1.69.69,2.35,2.35,0,0,0-.66,1.7V84.77a2.35,2.35,0,0,0,.66,1.7,2.26,2.26,0,0,0,1.69.69,2.22,2.22,0,0,0,1.68-.69,2.36,2.36,0,0,0,.67-1.7V75.48a7.06,7.06,0,0,1,.6-3A4.6,4.6,0,0,1,32,70.55a3.48,3.48,0,0,1,2-.63,3.12,3.12,0,0,1,.88.12l.53.16a3.93,3.93,0,0,0,1.33.27,2.06,2.06,0,0,0,1.49-.61A2.4,2.4,0,0,0,38.85,68a2,2,0,0,0-.8-1.56,3,3,0,0,0-2-.67,8,8,0,0,0-3.6.84"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient"
          x1="-0.06"
          y1="144.14"
          x2="0.94"
          y2="144.14"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6678.89, 66.83)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#473489"/>
          <stop offset="0.03" stopColor="#473489"/>
          <stop offset="1" stopColor="#5b63ad"/>
          <stop offset="1" stopColor="#5b63ad"/>
        </linearGradient>
        <clipPath id="lc-clip-path-2" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M47.8,82.42a5.92,5.92,0,0,1-2.15-2.31,7,7,0,0,1-.8-3.41,7.15,7.15,0,0,1,.8-3.43,5.91,5.91,0,0,1,2.15-2.33,5.9,5.9,0,0,1,6,0A6,6,0,0,1,56,73.27a7.15,7.15,0,0,1,.8,3.43,7,7,0,0,1-.8,3.41,6,6,0,0,1-2.15,2.31,5.9,5.9,0,0,1-6,0M45.4,67.2a10.15,10.15,0,0,0-3.84,3.88,11.91,11.91,0,0,0,0,11.24,10,10,0,0,0,3.84,3.86,11.31,11.31,0,0,0,10.79,0A10,10,0,0,0,60,82.32a11.91,11.91,0,0,0,0-11.24,10.11,10.11,0,0,0-3.85-3.88,11.16,11.16,0,0,0-10.79,0"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient-2"
          x1="-0.06"
          y1="144.54"
          x2="0.94"
          y2="144.54"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6678.78, 65)"
          xlinkHref="#lc-linear-gradient"
        />
        <clipPath id="lc-clip-path-3" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M71.85,82.42a5.92,5.92,0,0,1-2.15-2.31,7,7,0,0,1-.81-3.41,7.15,7.15,0,0,1,.81-3.43,5.91,5.91,0,0,1,2.15-2.33,5.88,5.88,0,0,1,6,0A6,6,0,0,1,80,73.27a7.26,7.26,0,0,1,.8,3.43,7.15,7.15,0,0,1-.8,3.41,6,6,0,0,1-2.16,2.31,5.88,5.88,0,0,1-6,0M69.44,67.2a10.15,10.15,0,0,0-3.84,3.88,11.91,11.91,0,0,0,0,11.24,10,10,0,0,0,3.84,3.86,11.31,11.31,0,0,0,10.79,0,10,10,0,0,0,3.86-3.86,11.91,11.91,0,0,0,0-11.24,10.13,10.13,0,0,0-3.86-3.88,11.16,11.16,0,0,0-10.79,0"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient-3"
          x1="-0.06"
          y1="145.06"
          x2="0.94"
          y2="145.06"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6678.66, 62.65)"
          xlinkHref="#lc-linear-gradient"
        />
        <clipPath id="lc-clip-path-4" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M95.53,81.36a7,7,0,0,1-1.61-4.7A6.91,6.91,0,0,1,95.53,72a5.54,5.54,0,0,1,8.28,0,7.67,7.67,0,0,1,0,9.38,5.48,5.48,0,0,1-8.26,0m1.21-14.67a8.13,8.13,0,0,0-2.62,2.06v-.16a2.36,2.36,0,0,0-.67-1.7,2.4,2.4,0,0,0-3.37,0,2.35,2.35,0,0,0-.66,1.7v24a2.34,2.34,0,0,0,.66,1.72,2.28,2.28,0,0,0,1.69.67,2.24,2.24,0,0,0,1.68-.69,2.35,2.35,0,0,0,.67-1.7V84.92a9.41,9.41,0,0,0,2.76,1.86,8.38,8.38,0,0,0,3.54.77,8.79,8.79,0,0,0,4.8-1.39,9.68,9.68,0,0,0,3.43-3.88,12.39,12.39,0,0,0,1.25-5.62,12.12,12.12,0,0,0-1.27-5.6,9.78,9.78,0,0,0-3.47-3.86,8.92,8.92,0,0,0-4.9-1.39,7.47,7.47,0,0,0-3.52.88"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient-4"
          x1="-0.06"
          y1="145.58"
          x2="0.94"
          y2="145.58"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6678.18, 60.25)"
          xlinkHref="#lc-linear-gradient"
        />
        <clipPath id="lc-clip-path-5" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M114.48,58.86a2.35,2.35,0,0,0-.66,1.7V84.77a2.35,2.35,0,0,0,.66,1.7,2.4,2.4,0,0,0,3.37,0,2.35,2.35,0,0,0,.66-1.7v-10a4.67,4.67,0,0,1,1.24-3.31,4.48,4.48,0,0,1,3.43-1.31,3.31,3.31,0,0,1,2.95,1.23,6,6,0,0,1,.84,3.39v10a2.36,2.36,0,0,0,.67,1.7,2.4,2.4,0,0,0,3.37,0,2.35,2.35,0,0,0,.66-1.7v-10a12.33,12.33,0,0,0-1.48-6.56c-1-1.58-2.78-2.37-5.37-2.37a6.73,6.73,0,0,0-3.62,1,9.64,9.64,0,0,0-2.69,2.41V60.56a2.35,2.35,0,0,0-.66-1.7,2.4,2.4,0,0,0-3.37,0"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient-5"
          x1="-0.06"
          y1="146.09"
          x2="0.94"
          y2="146.09"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6678.83, 57.96)"
          xlinkHref="#lc-linear-gradient"
        />
        <clipPath id="lc-clip-path-6" transform="translate(-2.33 -36.91)">
          <path
            fill="none"
            d="M39.5,47a1.1,1.1,0,0,0-.72.26l-6.33,5.26a.59.59,0,0,1-1-.46V49.38a1.13,1.13,0,0,0-1.13-1.13H19.58a1.14,1.14,0,0,0-1.13,1.13V63a1.11,1.11,0,0,1-.4.87L6,73.92a1.91,1.91,0,0,0-.68,1.43v.06a1.9,1.9,0,0,0,3.12,1.43L21.85,65.68a1.15,1.15,0,0,0,.41-.87V53.18a1.13,1.13,0,0,1,1.13-1.13h3.16a1.14,1.14,0,0,1,1.13,1.13v5.88a1.13,1.13,0,0,0,1.85.87L38.6,52.4a1.12,1.12,0,0,1,1.5.05l8.53,8.22a1.15,1.15,0,0,0,.79.32h58.2a1.9,1.9,0,0,0,0-3.8H51a1.14,1.14,0,0,1-.78-.32l-9.88-9.52a1.09,1.09,0,0,0-.78-.31Z"
          />
        </clipPath>
        <linearGradient
          id="lc-linear-gradient-6"
          x1="-0.06"
          y1="144.72"
          x2="0.94"
          y2="144.72"
          gradientTransform="matrix(0, 46.56, 46.56, 0, -6680.15, 64.29)"
          xlinkHref="#lc-linear-gradient"
        />
      </defs>
      <g clipPath="url(#lc-clip-path)">
        <rect
          fill="url(#lc-linear-gradient)"
          x="24.18"
          y="65.2"
          width="15.69"
          height="22.58"
          transform="translate(-9.64 -33.42) rotate(-5.6)"
        />
      </g>
      <g clipPath="url(#lc-clip-path-2)">
        <rect
          fill="url(#lc-linear-gradient-2)"
          x="39.14"
          y="64.82"
          width="23.33"
          height="23.71"
          transform="translate(-9.57 -31.59) rotate(-5.6)"
        />
      </g>
      <g clipPath="url(#lc-clip-path-3)">
        <rect
          fill="url(#lc-linear-gradient-3)"
          x="63.18"
          y="64.82"
          width="23.33"
          height="23.71"
          transform="translate(-9.45 -29.24) rotate(-5.6)"
        />
      </g>
      <g clipPath="url(#lc-clip-path-4)">
        <rect
          fill="url(#lc-linear-gradient-4)"
          x="88.04"
          y="64.88"
          width="23.23"
          height="31.04"
          transform="translate(-9.7 -26.8) rotate(-5.6)"
        />
      </g>
      <g clipPath="url(#lc-clip-path-5)">
        <rect
          fill="url(#lc-linear-gradient-5)"
          x="112.44"
          y="57.37"
          width="20.6"
          height="30.59"
          transform="translate(-8.83 -24.59) rotate(-5.6)"
        />
      </g>
      <g clipPath="url(#lc-clip-path-6)">
        <rect
          fill="url(#lc-linear-gradient-6)"
          x="4.05"
          y="42.02"
          width="106.71"
          height="40.5"
          transform="translate(-8.13 -31.01) rotate(-5.6)"
        />
      </g>
    </svg>
  );
}
